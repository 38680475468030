.profile-category {
  margin-bottom: 10px;
  font-size: 16px;
}

.bank-account-card {
  border: 1px solid gray;
  border-radius: 10px;
  width: 300px;
  height: 150px;
  margin: 10px;
}

.bank-account-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bank-account-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.notes-card {
  border: 1px solid gray;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0px;
}

.form-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 0px;
}

@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

.features {
    border-radius: 3px;
    border: 1px solid #BABABA;
    color: #7C7C7C;
    margin-bottom: 10px;
    font-size: 12px;
}
.features-selected {
    background-color: #EFEDFF;
    border: 1px solid #1d4c7f;
    color: #1d4c7f;
    border-radius: 3px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
}
::placeholder {
    color: #A3A3A3;
}
.features-container {
    display: flex;
    flex-direction: column;
}
textarea:focus { 
    outline: none !important;
    border-color: #1d4c7f;
}
.comments-box {
    border-radius: 3px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #BABABA;
    font-size: 12px;
    padding: 10px;
    height: 130px;
}
.rating { 
  border: none;
  float: left;
}
.star {
    cursor: pointer;
}
.rating > input { 
    display: none; 
} 
.rating > label:before { 
  margin: 15px;
  font-size: 1.45em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}
.rating > label { 
  color: #ddd; 
  float: right; 
}
.submit-button{
    background-color: #1d4c7f;
    color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 3px 3px 0px #1d4c7f;
    text-align: center;
    padding: 5px 0px;
}
.rating > input:checked ~ label, 
.rating:not(:checked) > label:hover, 
.rating:not(:checked) > label:hover ~ label { color:#FFD700; } 
.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, 
.rating > input:checked ~ label:hover ~ label { color:#FFD700; }  
.heading-1 {
    color: #11426C;
    font-weight: bolder;
    font-family: 'Nunito Sans';
    font-size: 18px;
}
.heading-2 {
    color: #11426C;
    font-weight: bold;
    font-family: 'Nunito Sans';
    font-size: 16px;
}
.heading-3 {
    color: #11426C;
    font-weight: bold;
    font-family: 'Nunito Sans';
    font-size: 14px;
}
.Simple-Card-View {
    /* padding: 10px; */
    border-radius: 4px;
    border: 1px solid #FFFFFF;
  }
  
  .Graph-Card-View {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 1px 2px 6px #8B9DAF26;
    background-color: #FFFFFF;
    border-radius: 4px;
    text-align: center;
  }
  
  .Graph-Card-View:hover {
    box-shadow: 0px 3px 15px #343B4029 !important;
  }
  
  .Button {
    height: 35px;
    display: flex;
    color: #43464B;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid #767171;
    background-color: #FFFFFF;
  }
  
  .Primary-Button {
    color: white;
    background-color: #3994AE;
  }
  
  .Primary-Button:hover {
    box-shadow: black;
    color: white;
    border-color: black;
    background-color: #3994AE !important;
  }
  
  .Secondary-Button {
    color: #3994AE;
    background-color: white;
  }
  
  .Secondary-Button:hover {
    color: white;
    background-color: #3994AE;
  }
  
  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 30px;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: #3994AE;
    cursor: pointer;
  }

  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.24s ease-out;
  }

  .sidebar.active {
    transform: translateX(0%);
  }
.heading-1 {
    color: #11426C;
    font-weight: bolder;
    font-family: 'Nunito Sans';
    font-size: 18px;
}
.heading-2 {
    color: #11426C;
    font-weight: bold;
    font-family: 'Nunito Sans';
    font-size: 16px;
}
.heading-3 {
    color: #11426C;
    font-weight: bold;
    font-family: 'Nunito Sans';
    font-size: 14px;
}
.content-text {
    font-size: 16px;
    font-family: 'Nunito';
    color: #52606C;
}
.edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 30px;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: #40A7FF;
    cursor: pointer;
}
.campus-count {
    background: #F7F9FC 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #40A7FF;
    font-family: 'Nunito';
    height: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
    padding-left: 10px;
}

.verify {
    height: 35px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
}
.clickable {
    cursor: pointer;
    color: #40A7FF;
}

::placeholder {
  color:#9E9E9E;
  opacity: 1;
}
ul {
    margin-bottom: 10px;
  }

  .custom-checkbox input[type=checkbox]:after {
    content: " ";
    background-color: #40A7FF;
    border-radius: 4px;
    display: inline-block;
    visibility: visible;
  }

  .custom-checkbox input[type=checkbox]:checked:after {
    content: "\2714";
    border-radius: 2px;
    display: block;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: white;
  }

  .fill-remaining {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
  }

  .custom-secondary-button {
    color: #40A7FF;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    border: 1px solid #40A7FF;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  .custom-secondary-button:hover {
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    border: 1px solid white;
    background-color: #40A7FF;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }

  ul:nth-of-type(1) {
    list-style-type: disc;
  }

  .more__actions {
    width: 200px;
    position: absolute ;
    top: 35px;
    right: 10px;
    background-color: #FFFFFF; 
    border-radius: 4px;
    box-shadow: 1px 4px 6px rgba(69, 69, 69, .3);
    cursor: pointer;
    border: 1px solid #CDCFD6;
  }
  
  .more__item:hover {
    background-color: #CDCFD6;
  }
.card {
  margin: 15px;
  width: 200px;
  padding: 15px;
  min-height: 90px;
  border-radius: 4px;
  box-shadow: 1px 2px 6px #8b9daf26;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.card-title {
  font-size: 20px;
  text-align: center;
  color: gray;
  font-family: "Poppins", sans-serif;
}

.card-content {
  font-size: 18px;
  margin-top: 5px;
  color: #152a53;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.card-subtitle {
  font-size: 10px;
  text-align: center;
  color: gray;
  font-family: "Poppins", sans-serif;
}

.table-bordered {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

.table-bordered td {
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 15px;
  text-align: left;
}

.table-bordered th {
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 15px;
  text-align: left;
  background-color: #e7e7e7;
}
.table-bordered tr:nth-child(even) {
  background-color: #f5f5f5;
}

.table-row-selection:hover {
  cursor: pointer;
}

.extended-row-background {
  padding: 10px;
  box-shadow: 1px 2px 6px #8b9daf26;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 15px;
}

.extended-row-content {
  font-size: 20px;
  text-align: center;
  color: #152a53;
}

.extended-row-title {
  font-size: 14px;
  text-align: center;
  color: gray;
}

.toggle-switch {
  position: static;
}
.toggle-switch input {
  display: none;
}

::placeholder {
  opacity: 0.5;
}
.holder::placeholder {
  font-size: 20px;
  text-align: center;
}

.slider-text {
  width: 75px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-text:before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider-text {
  background-color: #1d4c7f;
}
input:focus + .slider-text {
  box-shadow: 0 0 1px #1d4c7f;
}
input:checked + .slider-text:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}
.switch-on {
  display: none;
  position: relative;
  left: -10px;
}
.switch-off {
  position: relative;
  right: -5px;
}
.switch-on,
.switch-off {
  color: white;
  font-size: 12px;
}
input:checked + .slider-text .switch-on {
  display: block;
}
input:checked + .slider-text .switch-off {
  display: none;
}
.slider-text.round {
  border-radius: 40px;
}
.slider-text.round:before {
  border-radius: 50%;
}

.input-row-styling {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}

.input-row-elements {
  margin: auto 0px;
  width: 200px;
}

.modalstyle {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
.modal-sub-header {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: rgba(67, 70, 75, 1);
  opacity: 1;
}
.input-referal {
  height: 40px;
  border: 0.5px solid #d5d5d5;
  border-radius: 4px;
  width: 250px;
  color: #373B41;
}
.submit-referal {
  background: #1d4c7f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #43464b29;
  border-radius: 4px;
  width: 250px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #FFFFFF;
}
.modal-header {
  margin-top: 12px;
  margin-left: 15px;
}
.success-header {
  background: #1d4c7f;
  width: 250px;
  height: 40px;
  text-align: center;
  margin-top: 30px;
  border-radius: 4px;
}
.refer-button {
  font-size: 18px;
  font-weight: bold;
  width: 200px;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff29;
  border: 0.5px solid #ffffff;
  border-radius: 25px;
  opacity: 1;
}

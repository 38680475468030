
* {
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out;
}

.hover,
.link {
  cursor: pointer;
}

input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea {
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;
  border-style: solid;
}

input[type='date'] {
  border-radius: 5px;
  box-shadow: inset 0px 0px 0px 0px hsl(0, 0%, 80%);
  width: 100%;
  padding-left: 10;
  border: 1px solid hsl(0, 0%, 80%);
  height: 50;
}

.config-select .css-1rtrksz {
  height: 40px;
}

.cursor{
  cursor: pointer;
}

.config-select input {
  height: 30px;
}
::placeholder {
  color: #808080;
  opacity: .5;
  font-size: 15px;
}

button,
input[type='button'] {
  position: relative;
}

button[disabled] {
  background-color: #a8a8a8;
  border-color: #a8a8a8;
  color: white;
}

input[type=checkbox] {
 height: 20px;
 width: 20px;
}

input[type=checkbox]:after {
  content: " ";
  background-color: #1d4c7f;
  border-radius: 4px;
  display: inline-block;
  visibility: visible;
}

.virtual-meeting input[type=checkbox]:after {
  content: " ";
  background-color: #007fd2 !important;
  border-radius: 4px;
  display: inline-block;
  visibility: visible;
}

input[type=checkbox]:checked:after {
  content: "\2714";
  border-radius: 2px;
  display: block;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: white;
}
.printable_test_paper_background {
  display: none;
}

.printable{
  display: none;
}

@media print {
  .printable{
    display: block;
  }
  .printable_test_paper_background {
    position: fixed;
    margin-top: 10%;
    font-size: 14px;
    font-family: sans-serif;
  }
  .hide-in-print {
    display: none;
    visibility: hidden;
  }
}
#preventcopy {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}
.containerCheckbox {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerCheckbox input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: solid #ddd 2px;
}

.containerCheckbox input:checked ~ .checkmark {
  background-color: #88a0d3;
  border-radius: 4px;
  border: solid #88a0d3 2px;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.containerCheckbox input:checked ~ .checkmark:after {
  display: block;
}

.selectDropDownList * {
  max-height: 150px;
}

.containerCheckbox .checkmark:after {
  position: 'fixed';
  top: 20%;
  left: 35%;
  transform: 'translate(-50%, -50%)';
  text-align: 'center';
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-checkbox:checked::after {
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  border-radius: 50px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 20px;
}

.box {
  padding: 15px 30px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 1px hsla(0, 0%, 0%, 0.2);
}

.table td {
  padding-bottom: 5px;
}

/* For MathJax Font size and table view in two column view in print assignment*/
.two-column .mjx-table {
  max-width: 500px !important;
}

.mjx-table .mjx-char {
  font-size: 75% !important;
  white-space: normal;
}

.two-column .mjx-table .mjx-mrow {
  line-height: normal;
}

.mjx-mrow {
  white-space: pre;
}

/* For Automatic line break */
.two-column .mjx-table .mjx-mtd {
  white-space: normal;
}

.two-column .mjx-chtml {
  white-space: normal;
}

.two-column .mjx-munderover {
  white-space: nowrap;
}

/* Avoid option layout break */
.two-column .option-view {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.two-column .copyright {
  background-color: #ffffff;
}

/* Break MathJax in two column view support */
.two_column_solution .mjx-table {
  max-width: 500px !important;
  page-break-inside: auto;
}

.two_column_solution {
  max-width: 500px !important;
}

.two_column_solution .mjx-chtml {
  white-space: normal;
}

.two_column_solution .mjx-mtd {
  white-space: pre-line;
}

/* For MathJax Fonts in print assignment*/
.font-sans-serif .mjx-table .mjx-char {
  font-family: sans-serif;
}

.font-times-new-roman .mjx-table .mjx-char {
  font-family: 'Times New Roman';
}

.font-monospace .mjx-table .mjx-char {
  font-family: monospace;
}

.font-arial .mjx-table .mjx-char {
  font-family: arial;
}

.font-baskerville .mjx-table .mjx-char {
  font-family: 'baskerville';
}

.header_text {
  color: #343b40;
  font-size: 16px;
  font-weight: bolder;
}

.secondary_text {
  color: #525252;
  font-size: 14px;
}
.tertiary_button {
  color: #7E8398;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid #7E8398;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.tertiary_button:hover {
  color: #FFFFFF;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid white;
  background-color: #7E8398;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.secondary_button {
  color: #1d4c7f;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid #1d4c7f;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.secondary_button:hover {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid white;
  background-color: #1d4c7f;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.primary_button {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid #1d4c7f;
  background-color: #1d4c7f;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.progress_bar {
  background-color: #ebebeb;
  position: relative;
  height: 20px;
  width: '90%';
  border-radius: 50px;
  border: 1px solid #ddd;
}

.progress_bar_fill {
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.card-view {
  background: white;
  margin-top: 10px;
  font-size: 20px;
  padding: 10px;
  border: 0;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
}

.class_board_secondary_button {
  color: #11426C;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid #11426C;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.class_board_secondary_button:hover {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid white;
  background-color: #11426C;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.class_board_primary_button {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid #11426C;
  background-color: #11426C;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

/* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */

.box-view {
  background: white;
  margin-top: 10px;
  height: 100px;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  min-width: 100px;
  max-width: 200px;
  border: 0;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
  text-align: center;
}

.font-14 {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.font-15 {
  font-size: calc(15px + (26 - 15) * ((100vw - 300px) / (1600 - 300)));
}

.font-12 {
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
}

.font-18 {
  font-size: calc(18px + (34 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.font-16 {
  font-size: calc(16px + (32 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.image-question-view {
  background: white;
  margin-top: 10px;
  font-size: 14px;
  padding: 10px;
  border: 0;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
  width: 380px;
}

.card-view .mjx-chtml {
  white-space: normal;
}

.borderless td,
.borderless th {
  border: none;
}

td .question-analysis {
  border: none;
}
.table-att {
  width: auto;
  overflow-x: scroll;
  margin-right: 5em;
  margin-left: 5em;
  overflow-y: visible;
}
.headcol-1 {
  position: sticky;
  width: 20%;
  left: 20;
  white-space: nowrap;
  background-color: #f8f8f8;
}
.headcol {
  position: sticky;
  width: 5%;
  right: 0;
  background-color: #f8f8f8;
}


.date-height {
  height: 40px !important;
  margin-bottom: 0px;
}

.repeat-block {
  background-color: #FFFFFF;
  box-shadow: rgba(52, 59, 64, 0.08);
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 10px;
}

.hide-settings {
  pointer-events: none;
  opacity: 0.4;
}
.economy-button {
  color: #1d4c7f;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border: 1px solid #1d4c7f;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #4B4B4B;
}
.pagination>li.active>a {
  color: #fff;
  background-color: #40A7FF;
  border-radius: 4px;
}
.pagination > li > a:hover {
  background-color:  #52606C;
  color: white;
  border-radius: 4px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container {
  width: inherit;
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
  cursor: pointer;
}

.react-datepicker__input-container input {
  width: inherit;
  height: 100%;
  padding-left: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem !important;
}

.black-white-button {
  width: 100px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #343737;
  border: 1px solid #343737;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 18px;
  font-style: normal;
  cursor: pointer;
}

.white-black-button {
  width: 100px;
  color: #343737;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e6e6;
  border: 1px solid #343737;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 18px;
  font-style: normal;
  cursor: pointer;
}

.New-Input {
  color: #43464B;
  margin-top: 0px;
  border-radius: 0px;
  border: 0px;
  border-bottom: 2px solid #DDD;
  background-color: inherit;
  padding: 5px 10px 5px 10px;
}

.New-Input:focus {
  border: 0px;
  border-bottom: 2px solid #673AB7 !important;
}

.New-Input-Hover {
  border: 0px;
}

.New-Input-Hover:hover {
  border-bottom: 2px solid #ddd;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.frame::-webkit-scrollbar:vertical {
  width: 12px !important;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  border: 1px solid #454545 !important; /* should match background, can't be transparent */
  background-color: #3D66DA7A !important;
}

.react-datepicker__input-container input {
  margin: 0px;
  border: 1px solid rgb(124, 148, 186);
  padding: 12px;
  padding-left: 20px;
}

.weekDays-selector input {
  display: none!important;
}

.weekDays-selector input[type=checkbox] + label {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #343737;
  background: #ffffff;
  height: 40px;
  width: 40px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.weekDays-selector input[type=checkbox]:checked + label {
  background: #17ACE8;
  color: #ffffff;
}

.weekDays-selector {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.zm-modal-footer-default-actions button:nth-child(2) {
  display: none;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

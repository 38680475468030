.rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

.rc-time-picker-clear,
.rc-time-picker-clear-icon:after {
    font-size: 15px;
    line-height: 2;
    outline: none;
  }
.rc-time-picker-panel-select,
.rc-time-picker-input,
.rc-time-picker-panel-input {
    font-family: "Consolas", sans-serif;
    font-size: 16px;
    overflow-y: hidden;
  }

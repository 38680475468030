.App {
    background-color: #F7F9FA;
    height: 100%;
  }
  
  .hideBackground {
    background: rgba(0, 0, 0, 0.15) !important;
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input:focus, input.form-control:focus {
      outline:none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
  }
  
  .loadingSpinner {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #4786FF; /* Blue */
    border-radius: 50%;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .key-pad-press:active {
    box-shadow: 0px 3px 15px #ccc;
  }
  
  .headerLeft {
    border-right: '1px solid #EFEFEF';
    padding-right: 50;
    width: 300px;
  }
  
  .footer {
    font-family: 'Montserrat', sans-serif;
    background-color: #191919;
    color: #c4c4c4;
    font-size: 1.0em;
    padding: 10px 0;
    text-align: "center";
  }
  
  .headerRight {
    border-left: 1px solid #EFEFEF;
    position: absolute;
    right: 0px;
    width: 370px;
    display: flex;
    justify-content: flex-end;
  }
  
  .boxElement {
    width: 300px;
    justify-content: space-between;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  
  .flexcontainer {
     display: flex;
     flex-flow: row wrap;
  }
  
  .modalButtonView {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
  
  .selectedCardView {
    border: 1px solid #6B76FF;
    background-color: #F0F6FF !important;
    box-shadow: 0px 3px 15px rgba(71, 134, 255, 0.1) !important;
  }
  
  .simpleCardView {
    border: 1px solid #BFBFBF;
    border-radius: 4px;
  }
  
  .bottomBorderView {
    border-bottom: 1px solid #BFBFBF;
  }
  
  .cardView {
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 15px rgba(65, 61, 74, 0.1);
    white-space: pre-line;
  }
  
  .cardViewHover {
    cursor: pointer;
  }
  
  .cardViewHover:hover {
    box-shadow: 0px 3px 15px #ccc !important;
  }
  
  .hideBoxShadowHover:hover {
    box-shadow: 0px 0px 0px #BFBFBF !important;
  }
  
  textarea {
    border: 1px solid #BFBFBF;
    border-radius: 5px;
    background-color: #FAFAFA;
    padding-left: 10px;
    color: #373b41;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
  }
  
  input {
    border: 1px solid #BFBFBF;
    border-radius: 5px;
    background-color: #FAFAFA;
    padding-left: 10px;
    padding-right: 10px;
    color: #373b41;
  }
  
  *:focus {
      outline: none;
  }
  
  ::placeholder {
    color: #A8A8A8;
    opacity: 0.7;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #A8A8A8;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: #A8A8A8;
  }
  
  .Header {
    position: fixed;
    justify-content: space-between;
    height: 70px;
    background-color: white;
    width: 100%;
    align-items: center;
    box-shadow: 0px 0px 6px rgba(55, 59, 65, 0.03);
    z-index: 5;
    border-bottom: 1px solid #EFEFEF;
    top: 0;
  }
  
  .Timer {
    display: flex;
    justify-content: center;
  }
  
  .Sidebar_Padding {
    padding: 10px 10px 10px 60px;
  }
  
  .Test_Sidebar_Padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .SideBar {
    height: 100%;
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow-x: hidden;
    padding-top: 70px;
    box-shadow: 3px 0 6px rgba(55, 59, 65, 0.03);
  }
  
  .Circle {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  

  .Correct:hover {
    background-color: #00938C !important;
    border: 1px solid #04BAB1;
  }
  
  .Incorrect:hover {
    background-color: #FF5731 !important;
    border: 1px solid #FF7657;
  }
  
  .Marked:hover {
    background-color: #E56A00 !important;
    border: 1px solid #FF9300;
  }
  
  .Attempted:hover {
    background-color: #674EFF !important;
    border: 1px solid #8774FF;
  }
  
  .Unattempted:hover {
    background-color: #524F4F !important;
    border: 1px solid #777777;
  }
  
  .Unvisited:hover {
    background-color: #C9C9C9 !important;
    border: 1px solid #E5E5E5;
  }
  
  .Unvisited:hover p {
    color: #373B41 !important;
  }
  
  .Key_Used:hover {
    background-color: #FF947F !important;
    border: 1px solid #FF7657;
  }
  
  .Overview {
    height: 100%;
    width: 370px;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #FFFFFF;
    overflow-x: hidden;
    padding-top: 100px;
    box-shadow: -3px 0 6px rgba(55, 59, 65, 0.03);
  }
  
  .MainPage {
    margin-top: 50px;
    margin-left: 300px;
    padding-left: 60px;
    padding-right: 60px;
    overflow-x: hidden;
    padding-bottom: 50px;
    height: 100%;
  }
  
  .RightOverview {
    margin-right: 370px;
  }
  .Leader-Button {
    height: 35px;
    display: flex;
    color: #43464B;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid #9EA0A5;
    background-color: #FFFFFF;
  }
  
  .Leader-Button:hover {
    background-color: #EFEFEF;
  }
  
  .Leader-Button:active {
    bottom: -4px;
    box-shadow: 0px 4px 8px rgba(11, 14, 255, 0.3);
  }
  

  .Button {
    display: flex;
    border: 1px solid #7979796b;
    font-weight: 600;
    padding: 5px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 35px;
    cursor: pointer;
  }
  
  .CheatSheetKnownButton {
    display: flex;
    border: 1px solid #32BE3D;
    color: #32BE3D;
    font-weight: 600;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    height: 35px;
    cursor: pointer;
  }
  
  .CheatSheetUnKnownButton {
    display: flex;
    border: 1px solid #EF9943;
    color: #EF9943;
    font-weight: 600;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    height: 35px;
    cursor: pointer;
  }
  
  .secondary-Button {
    border-color: #1d4c7f;
    color:#1d4c7f;
  }

  .secondary-Button:hover {
    color: white;
    background-color: #1d4c7f;
  }
  
  .Tertiary-Button {
    display: flex;
    color: #1d4c7f;
    font-weight: 600;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    height: 35px;
    cursor: pointer;
  }
  
  .Tertiary-Button:hover {
    color:#1d4c7f;
  }

  textarea:focus, input:focus{
    outline: none !important;
  }
  
  .primary-Button {
    background-color: #1d4c7f;
    border-color: #1d4c7f;
    color: white;
    box-shadow: #1d4c7f4D;
  }
    
  .primary-Button:hover {
    background-color: #1d4c7f;
    border-color: #1d4c7f;
    color: white !important;
  }
  
  .home-button {
    display: flex;
    font-weight: 600;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    height: 35px;
  }
  
  .home-primary-button {
    background-color: #017d90;
    border-color: #017d90;
    color: white;
    box-shadow: #01778a;
  }
  
  .home-primary-button:hover {
    background-color: #015f6e;
    border-color: #015f6e;
    color: white !important;
    cursor: pointer !important;
  }
  
  .Button:hover img {
    filter: brightness(0) invert(1);
  }
  
  .Button:active {
    box-shadow: 0px 4px 8px rgba(11, 14, 255, 0.3);
    bottom: -4px;
  }
  
  a:hover {
    text-decoration: none !important;
  }
  
  .btn-primary:focus {
    background-color: #1d4c7f;
    border-color:#1d4c7f ;
  }
  
  .Simple-Card-View {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
  }
  
  .green-white-button {
    width: 100px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #343737;
    border: 1px solid #AED580;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
  
  .green-white-button-inverted {
    width: 125px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #77B33D;
    cursor: pointer;
    border: 1px solid #AED580;
    box-sizing: border-box;
  }
  
  #zmmtg-root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
  }
  
  .frame::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }
  
  .frame::-webkit-scrollbar:vertical {
    width: 12px !important;
  }
  
  .frame::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    border: 1px solid #454545 !important; /* should match background, can't be transparent */
    background-color: #3D66DA7A !important;
  }

  .table-bordered {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

.table-bordered td {
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 15px;
  text-align: left;
}

.table-bordered th {
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 15px;
  text-align: left;
  background-color: #e7e7e7;
}
.table-bordered tr:nth-child(even) {
  background-color: #f5f5f5;
}
.refer-now-container {
    box-shadow: 0px 3px 6px rgba(67, 70, 75, 0.16);
    border-radius: 4px;
    opacity: 1;
    margin-bottom: 30px;
    height: 135px;
    background: url('/static/images/bubbles.png') no-repeat,
    linear-gradient(90deg, rgba(11, 161, 219, 1) 0%, rgba(68, 212, 201, 1) 100%)
    0% 0% no-repeat padding-box;
  }
  .refer-now-container-text {
    font-weight: 800;
    font-size: 26px;
    letter-spacing: 0;
    color: #ffffff;
  }
.progress {
    height: 24px;
    background-color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .complexity-tag {
    font-size: 14px;
    width: 100px;
    margin-right: 10px;
  }
  .progress-bar {
    height: 12px;
    margin-top: 7px;
    margin-left: 5px;
    border-radius: 0px 5px 5px 0px;
  }
  .complexity-count {
    font-size: 14px;
    font-weight: 700;
    padding-right: 20px;
    margin-top: 5px;
  }

  .insights-container {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(67, 70, 75, 0.08);
    border-radius: 4px;
    opacity: 1;
    display: flex;
  }
  .freshness-label {
    font-size: 28px;
    letter-spacing: 0;
    color: rgba(15, 198, 43, 1);
    opacity: 1;
  }
  .previous-year-questions {
    font-size: 28px;
    letter-spacing: 0;
    color: #43464b;
    font-weight: 700;
  }
  .insight-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}